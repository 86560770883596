// -------------------------------------------------------------------
// Omega Reset for Bourbon Neat
// http://joshfry.me/notes/omega-reset-for-bourbon-neat
// -------------------------------------------------------------------
@use "sass:selector";


@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) { margin-right: flex-gutter($grid-columns, $gutter); }
  &:nth-child(#{$nth}+1) { clear: none }
}



// --------------------------------------------------
// Creates a dark overlay that disappears on hover
// --------------------------------------------------
@mixin white-overlay {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255,255,255,0.4);
    transition: background .7s cubic-bezier(.25, .8, .25, 1);
    z-index: 5;
  }
}

// --------------------------------------------------
// Creates a dark overlay that disappears on hover
// --------------------------------------------------
@mixin overlay-transition {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,0.3);
    transition: background .7s cubic-bezier(.25, .8, .25, 1);
    z-index: 5;
  }

  &:hover {

    &::after {
      background: rgba(0,0,0,0.0);
    }
  }
}

// -------------------------------------------------------------
// absolute centers an element within a relative positioned div
// -------------------------------------------------------------
@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


// ---------------------------------------------
// Scss mixin and function to use rem units with pixel fallback.
// ---------------------------------------------
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}


@mixin skew {

    @include media($tablet) {
      transform: rotate(-5deg) skew(-5deg, 0);
      text-transform: uppercase;
    };
}

@mixin ab-fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin ghblack {
  font-family: $ghwebproblack;
  -webkit-font-smoothing: antialiased;
  font-weight: normal;
}

@mixin btn-load {
  @extend .mdl-btn;
      @include row();
      @include ghblack;
      text-align: center;
      width: 100%;
      max-width: 380px;
      background: transparentize($st_red, 0.2);
      padding: 15px 30px;
      text-transform: uppercase;
      text-decoration: none;
      border: 3px solid $st_red;
      border-radius: 4px;
      color: #fff;
      @include font-size(25px);
      border-radius: 2rem;
      margin: 0 auto;
      cursor: pointer;
      position: relative;
      z-index: 10;

      &:hover {
          background: transparentize($st_red, 0.5);
      }
}

@mixin link_states($color, $active: darken($color, 20%), $hover: darken($color, 20%)) {
    &:link,
    &:visited {
        color: $color;
    }

    &:active,
    &.active {
        color: $active;
    }

    &:hover,
    &:focus {
        color: $hover;
        text-decoration: none;
    }
}
