h1,
h2,
h3,
h4,
h5,
h6 {
  @include ghblack;
}

h1 {
  @include font-size(48px);
}

h2 {
  @include font-size(24px);
}

h3 {
  @include font-size(20px);
}

p {
  font-family: $opensans;
  @include font-size(14.5px);
}

.main--single {

  .main__content {

    h1 {
      text-transform: uppercase;
    }
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}


p {
  @include font-size(16px);
  line-height: 150%;
}

.text-uppercase {
  text-transform: uppercase;
}

a {
  color: $st_black;
  transition: all .2s;

  &:hover {
    color: $st_red;
    text-decoration: none;
  }
}

.inner {
  @include inner-container;
}


.close {
  @include position(absolute, 20px 20px null null);
  display: inline-block;
  width: 10px;
  height: 10px;
  overflow: hidden;

  &:hover {
    cursor: pointer;

    &::before, &::after {
      background: $st_red;
    }
  }

  &::before, &::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #333;
    transition: all ease-in-out .2s;
  }

  &::before {
    @include transform(rotate(45deg));
  }
  &::after {
    @include transform(rotate(-45deg));
  }
  &.big {
    @include transform(scale(3));
  }
}



.cd-top {
  display: inline-block;
  height: 50px;
  width: 50px;
  position: fixed;
  bottom: 30px;
  right: 10px;
  box-shadow: 0 0 10px rgba(#000, .05);
  /* image replacement properties */
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  background: rgba($st_red, .8) url(../images/cd-top-arrow.svg) no-repeat center 50%;
  visibility: hidden;
  opacity: 0;
  transition: opacity .3s 0s, visibility 0s .3s;

  &.cd-is-visible, &.cd-fade-out, .no-touch &:hover {
    transition: opacity .3s 0s, visibility 0s 0s;
  }

  &.cd-is-visible { /* the button becomes visible */
    visibility: visible;
    opacity: 0.5;
  }

  .no-touch &:hover {
    background-color: rgba($st_red, 1);
    opacity: 1;
  }
}


table {

  @include media($mobile) {
    float: none;
    width: 100% !important;
  };
}

.muted {
  color: $st_lightgray;
  text-transform: uppercase;
  @include font-size(17px);
}


#cookie-compliance {
  background-color: transparentize(#000, 0.1) !important;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  padding: 20px 0;
  position: relative;
  z-index: 9999;
  color: $st_white !important;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  
  @include media(desktop) {
    display: block;
  };
}

#cookie-compliance .cookie-compliance__inner {
  max-width: 1340px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1340px) {
  .cookie-compliance__inner {
    padding: 0 20px !important;
  }
}

#cookie-compliance .cookie-compliance__text {
  color: $st_white;
  font-size: 18px;
    
    a {
        color: $st_white;
    }
}

#cookie-compliance .cookie-compliance__button {
  @extend .btn--primary;
  position: relative;
  z-index: 99999;
}

