#branding {
	position: relative;
	margin-top: -100px;

    &.no-banner {
        min-height: 25vh;
    }

    &.no-promo {
        min-height: 35vh;
        @include media($desktop) {
            min-height: 45vh;
        };
    }

	@include media($mobile) {
		min-height: 0;
	};

    a {
        display: block;
    }

	/* Tryouts
    background-image: url('/images/body-bg.jpg');
    background-size: cover;
    background-attachment: fixed;
    */

	.promo-banner {
		@include inner-container;
		padding-top: 220px;
		padding-bottom: 25px;
		position: relative;
		z-index: 90;
	}

	.campaign-banner {
		color: #fff;
		background: transparentize($st_red, 0.15);
		@include transform(skew(0deg, -5deg));
		text-align: center;
		z-index: 100;
		opacity: 0.8;
        padding: 20px 0;
        position: absolute;
        top: calc(210px + 50%);
        left: 50%;
        transform: translate(-50%, calc(-50% - 110px)) skew(0deg, -5deg);
        width: 80%;


        @include media($desktop) {
            width: 75%;
		};

		h1 {
			@include font-size(24px);
			margin: 0;
			font-family: $ghwebpro;

            @include media($tablet) {
                @include font-size(40px);
            }
		}

		h2.subtitle {
			@include font-size(16px);
			margin: 0 0 0 5px;
			@include ghblack;
			text-transform: uppercase;

            @include media($tablet) {
                @include font-size(22px);
            }
		}
	}
}
