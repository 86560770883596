.section {
    @include row();
    margin-top: -100px;
    padding: 200px 0;

    &.section--color--white {
        color: $st_white;
    }
    &.section--color--red {
        color: $st_red;
    }
    &.section--color--black {
        color: $st_black;
    }

    h1 {
        color: $st_red;
    }


    .half {
        margin-bottom: 70px;

        @include media($desktop) {
            @include span-columns(6);
        };
    }

    li {
        list-style:initial;

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    iframe {
        width: 100%;
        height: 300px;
    }

}

#magasin {

    .half {
        margin-bottom: 0;
        border: 5px solid transparentize(#fff, 0.20);
    }
}
