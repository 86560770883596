
// Toggle Button Variables
$button-width: 48px;                        // The width of the button area
$button-height: 48px;                       // The height of the button area
$bar-thickness: 3px;                        // The thickness of the button bars
$button-pad: 9px;                           // The left/right padding between button area and bars.
$button-bar-space: 6px;                     // The spacing between button bars
$button-transistion-duration: 0.3s;         // The transition duration
$button-background-color: $st_black;   // The background color of the toggle button
$bar-color: rgb(255,255,255);               // The color of the bars

// Mobile menu variables
$row-height: 3.5em;                         // Height of each row
$frame: 1px solid rgba(255,255,255,0.25);    // The frame (border) color
$link_color: #e5e5e5;                       // Link text color
$row_hover: rgba(255,255,255,0.1);          // Background color for the row when hovering
$icon_color: #e5e5e5;                       // The expand/collapse icon color


%hmm_background {
    background-color: #363636; // Old browsers
}

#block-helium-mobile-menu-helium-mobile-menu-menu {
    position: relative;
    z-index: 20;
}

#block-helium-mobile-menu-helium-mobile-menu-menu-toggle {
    margin: 0;
    padding: 0;
}

.hmm-toggle {
    display: block;
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: $button-width;
    height: $button-height;
    font-size: 0;
    text-indent: -9999px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
    transition: background $button-transistion-duration;
}

.hmm-toggle:focus {
    outline: none;
}

.hmm-toggle span {
    display: block;
    position: absolute;
    top: ($button-height / 2) - ($bar-thickness / 2);
    left: $button-pad;
    right: $button-pad;
    height: $bar-thickness;
    background: $bar-color;
}

.hmm-toggle span::before,
.hmm-toggle span::after {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: $bar-thickness;
    background-color: #fff;
    content: "";
}

.hmm-toggle span::before {
    top: -$bar-thickness - $button-bar-space;
}

.hmm-toggle span::after {
    bottom: -$bar-thickness - $button-bar-space;
}

.hmm-toggle--htx {
    background-color: $button-background-color;
}

.hmm-toggle--htx span {
    transition: background 0s $button-transistion-duration;
}

.hmm-toggle--htx span::before,
.hmm-toggle--htx span::after {
    transition-duration: $button-transistion-duration, $button-transistion-duration;
    transition-delay: $button-transistion-duration, 0s;
}

.hmm-toggle--htx span::before {
    transition-property: top, transform;
}

.hmm-toggle--htx span::after {
    transition-property: bottom, transform;
}

/* active state, i.e. menu open */

.hmm-toggle--htx.is-active {
    background-color: darken($button-background-color, 10%);
}

.hmm-toggle--htx.is-active span {
    background: none;
}

.hmm-toggle--htx.is-active span::before {
    top: 0;
    transform: rotate(45deg);
}

.hmm-toggle--htx.is-active span::after {
    bottom: 0;
    transform: rotate(-45deg);
}

.hmm-toggle--htx.is-active span::before,
.hmm-toggle--htx.is-active span::after {
    transition-delay: 0s, $button-transistion-duration;
}


.mobile-menu {

    @extend %hmm_background;

    ul {
        padding: 0;
        margin: 0;
        width: 100%;
        list-style-type: none;

        li {
            display: block;
            width: 100%;
            float: none;
            position: relative;
            background: $button-background-color;

            &.has-expander {
                &.active-trail {
                    ul {
                        display: block;
                    }
                }

                ul {
                    display: none;
                }
            }

            &.expanded {

                ul {

                    li {
                        background: darken($button-background-color, 5%);
                    }
                }
            }

            a {
                display: block;
                width: 100%;
                padding: 0em 5%;
                border-top: $frame;
                text-align: left;
                text-decoration: none;
                position: relative;
                height: $row-height;
                line-height: $row-height;
                @include transition(all .1s linear);

                &:link,
                &:visited {
                    color: $link_color;
                }

                &:active,
                &.active {
                    background: $row_hover;
                }

                &:hover,
                &:focus {
                    background: $row_hover;
                }

            }

            .expander {
                position: absolute;
                right: 0;
                top: 0;
                width: 60px;
                height: 100%;
                text-align: center;
                z-index: 999;
                cursor: pointer;
                background: rgba(0,0,0,0.2);
                border-left: $frame;
                @include transition(background .1s linear);
            }

            .expander:hover {
                background: rgba(0,0,0,0.3);
            }

            .expander i {
                margin: 0;
                padding: 0;
                font-style: normal;
                @include transition(all .1s linear);

                &.icono-caretDown {
                    top: 3px;
                }
            }

            ul {
                // 2. level
                li {
                    a {
                        padding: 0em 10%;
                    }

                    // 3. level
                    li {
                        a {
                            padding: 0em 15%;
                        }


                        // 4. level
                        li {
                            a {
                                padding: 0em 20%;
                            }

                        }
                    }
                }
            }
            &.collapsed {

                ul {
                    display: none;
                }
            }
        }
    }

    &.mm-closed {
        ul {
            display: none;
        }
    }
}
