
@import "base";


// make the visual grid follow the container
@if ($visual-grid) {
  body:before {
      @include container;
  }
}


/* ==========================================================================
   																	App style
   ========================================================================== */


html {
	height: 100%;
	width: 100%;
	overflow-x: hidden;
}

body {
	font-family: $opensans;
	color: #333;
	height: 100%;
	width: 100%;
	background: #000;

	p {
		font-weight: normal;
	}
}

.section-parallax {
	min-height: 400px;
	background: transparent;
}

.section {

	@include media($tablet) {
		padding: 200px 0;
	};

	&.first {
		padding-top: 300px;
	}

	img {
    @include media($tablet) {
        //display: block;
        //margin: 50px auto 50px auto;
    };
  }

	h1 {
		text-align: center;
		text-transform: uppercase;
		@include skew;
		@include font-size(50px);
		margin: 0 0 30px 0;
		@include ghblack;

		@include media($tablet) {
			margin: 0 0 100px 0;
			@include font-size(70px);
		};
	}

	h2 {
		margin-bottom: 5px;
		text-transform: uppercase;
		@include font-size(25px);
	}

	p {
		margin: 0 0 20px 0;
	}

  &--skew {
    padding: 50px 0;
    background-size: cover;
    position: relative;
    overflow: hidden;
    clip-path: polygon(0 calc(0% + 50px), 100% 0, 100% calc(100% - 50px), 0 100%);

    @include media($tablet) {
      clip-path: polygon(0 calc(0% + 80px), 100% 0, 100% calc(100% - 80px), 0 100%);
    };

    @include media($desktop) {
      margin: $section-fit 0 $section-fit 0;
      clip-path: polygon(0 calc(0% + 150px), 100% 0, 100% calc(100% - 150px), 0 100%);
    };

    @include media($desktop-xl) {
      clip-path: polygon(0 calc(0% + 175px), 100% 0, 100% calc(100% - 175px), 0 100%);
    };

    .inner {
      position: relative;
      z-index: 10;
	  padding-top: 50px;
	  padding-bottom: 50px;
	  @include row;

	  @include media($tablet) {
		  padding: 50px 130px;
	  };

      @include media($desktop) {
        padding: 100px 50px;
      };

      @include media($desktop-xl) {
        padding: 100px 15px;
      };
    }
  }

  .half {
	  margin-bottom: 70px;

	  @include media($desktop) {
		  @include span-columns(6);
	  };
  }
}

.parallax-slider {
	//clip-path: polygon(0 5%, 100% 0%, 100% 95%, 0% 100%);
}



$g0: #000000;
$g1: #252525;
$g2: #666666;
$g3: #999999;
$g4: #bbbbbb;
$g5: #ffffff;

.gradient {
	position: absolute;
	bottom: -890px;
	height: 1290px;
	width: 100%;
	@include background-image(linear-gradient(top, rgba($g0,0) 0%,rgba($g0,0) 1%,rgba($g0,1) 31%,rgba($g0,0.78) 64%,rgba($g0,0) 100%));

	&.000000 {
		@include background-image(linear-gradient(top, rgba($g0,0) 0%,rgba($g0,0) 1%,rgba($g0,1) 31%,rgba($g0,0.78) 64%,rgba($g0,0) 100%));
	}

	&.252525 {
		@include background-image(linear-gradient(top, rgba($g1,0) 0%,rgba($g1,0) 1%,rgba($g1,1) 31%,rgba($g1,0.78) 64%,rgba($g1,0) 100%));
	}
	&.666666 {
		@include background-image(linear-gradient(top, rgba($g2,0) 0%,rgba($g2,0) 1%,rgba($g2,1) 31%,rgba($g2,0.78) 64%,rgba($g2,0) 100%));
	}
	&.999999 {
		@include background-image(linear-gradient(top, rgba($g3,0) 0%,rgba($g3,0) 1%,rgba($g3,1) 31%,rgba($g3,0.78) 64%,rgba($g3,0) 100%));
	}
	&.bbbbbb {
		@include background-image(linear-gradient(top, rgba($g4,0) 0%,rgba($g4,0) 1%,rgba($g4,1) 31%,rgba($g4,0.78) 64%,rgba($g4,0) 100%));
	}
	&.ffffff {
		@include background-image(linear-gradient(top, rgba($g5,0) 0%,rgba($g5,0) 1%,rgba($g5,1) 31%,rgba($g5,0.78) 64%,rgba($g5,0) 100%));
	}

    &.top {
        top: -690px;
    }
}

.cover {
	width: 100%;
	height: 100%;
	margin: -50px 0;
	padding: 50px 0;
}

.section {
	// color: $st_white;

	a {
		padding-bottom: 1px;
		border-bottom: 1px dotted $st_white;
		transition: all 0.15s ease;
		margin-bottom: 3px;
		display: inline-block;

		&:link,
		&:visited {

		}

		&:active,
		&.active {

		}

		&:hover,
		&:focus {
			border-bottom: 1px solid;
		}
	}

	&.color--white {
		color: $st_white;
		a {
			@include link_states($st_white);
		}
		h1 {
			color: $st_white;
		}
	}
	&.color--black {
		color: $st_black;

		a {
			@include link_states($st_black);
		}

		h1 {
			color: $st_black;
		}
	}
	&.color--red {
		color: $st_red;

		a {
			@include link_states($st_red);
		}

		h1 {
			color: $st_red;
		}
	}
  &.background--white {
    background-color: transparentize($st_white, 0.12);
    //.cover { background-color: transparentize($st_white, 0.12); }

    a {
      @include link_states($st_red);
    }
  }
  &.background--black {
    background-color: transparentize($st_black, 0.12);
    //.cover { background-color: transparentize($st_black, 0.12); }

    a {
      @include link_states($st_white);
    }
  }
  &.background--red {
    background-color: transparentize($st_red, 0.12);
    //.cover { background-color: transparentize($st_red, 0.12); }

    a {
      @include link_states($st_white);
    }
  }


	&--skew {
		&.background--white {
			background-color: transparentize($st_white, 0.12);
			.cover { background-color: transparentize($st_white, 0.12); }

			a {
				@include link_states($st_red);
			}
		}
		&.background--black {
			background-color: transparentize($st_black, 0.12);
			.cover { background-color: transparentize($st_black, 0.12); }

			a {
				@include link_states($st_white);
			}
		}
		&.background--red {
			background-color: transparentize($st_red, 0.12);
			.cover { background-color: transparentize($st_red, 0.12); }

			a {
				@include link_states($st_white);
			}
		}
	}
}

.maintenance-page {
    background: #000;
    color: white;
    font-size: 1.6em;
    text-align: center;
    padding: 60px;
}
