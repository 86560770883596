.mdl-btn {
  animation-duration: .1ms;
  animation-name: mui-node-inserted;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(0,0,0,.87);
  background-color: #FFF;
  transition: all .2s ease-in-out;
  display: inline-block;
  padding: 8px 26px;
  margin-top: 6px;
  margin-bottom: 6px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  touch-action: manipulation;
  background-image: none;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  user-select: none;
  font-size: 14px;
  letter-spacing: .03em;
  position: relative;
  overflow: hidden;
  border-bottom: 0 !important;

  &:hover {
    outline: 0;
    text-decoration: none;
  }
}

.btn--primary {
  @extend .mdl-btn;
  color: #FFF;
  background-color: $st_red;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

  &:hover {
    background-color: lighten($st_red, 10%);;
    box-shadow: 0 0 2px rgba(0,0,0,.12),0 2px 2px rgba(0,0,0,.2);
  }

  &:active {
    box-shadow: 0 10px 20px rgba(0,0,0,.19),0 6px 6px rgba(0,0,0,.23);
  }
}


.btn--ticket {
  @extend .mdl-btn;
  background: $st_red;
  color: #fff;
  position: relative;
  width: 160px;
  font-weight: bold;

    p {
        font-weight: bold;
    }


  &:after {
    content: "";
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    border-style: solid;
    border-width: 12px 0 12px 15px;
    border-color: transparent transparent transparent #fff;
    transition: all .2s ease-in-out;
  }

  &:hover {
    background-color: lighten($st_red, 5%);
    color: #fff;
  }

  p {
    margin: 10px 0;
    @include skew;
    @include font-size(20px);
  }

  &--free {
    cursor:not-allowed;
  }


  &--unavailable {
    background-color: $st_black;
    cursor: not-allowed;

    &:after {
      display: none;
    }

    &:hover {
      background-color: $st_black;
    }
  }
}

.btn--icon {

  @include font-size(15px);
  color: $st_red;
  font-weight: bold;
  text-transform: uppercase;
  transition: color 0.3s ease-in-out;
  display: block;

  i {
    @include font-size(40px);
    vertical-align: middle;
    margin-right: 10px;
    min-width: 40px;
    text-align: center;
  }

  &:hover {
    color: lighten($st_red, 5%);
    i {
      color: lighten($st_red, 5%);
    }
  }

}
