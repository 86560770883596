body.page-program-genrer {
    h1 {
        margin-left: 2.5%;
    }
}

/* ---- button-group ---- */

.button-group {

    @include media($mobile) {
        display: none;
    };

    @include media($desktop) {

    };

    margin-bottom: 10px;
    text-align: left;
    margin: 0 1.5%;
    margin-bottom: 20px;

    &:after {
        content: '';
        display: block;
        clear: both;
    }

    .button {
        @extend .btn--primary;
        padding: 10px 20px;
        margin: 0 8px 10px 0;
        font-family: $ghwebpro;
        @include font-size(17px);
    }
}

#event-filters-select {
    display: none;
    margin-bottom: 20px;
    @include media($mobile) {
        display: block;
    };

    select {
        padding: 0.5em;
        background: $st_red;
        border: 0;
        color: white;
        box-sizing: border-box;
        width: 100%;
        -webkit-appearance: none;
        font-size: 1.575rem;
        @include ghblack;
        text-transform: uppercase;
        background: $st_red url('../images/pil_stars.png') no-repeat 95% 50%;
    }
}


.button:hover {
    background-image: none;
    background-color: darken($st_red, 25%);
}

.button:active,
.button.is-checked {
    background-image: none;
    background-color: darken($st_red, 25%);
}

.button.is-checked {
    color: white;
    text-shadow: 0 -1px hsla(0, 0%, 0%, 0.8);
}

.button:active {
    box-shadow: inset 0 1px 10px hsla(0, 0%, 0%, 0.8);
}





/* ---- isotope ---- */

.grid {
    border: 1px solid #333;
}

/* clear fix */
.grid:after {
    content: '';
    display: block;
    clear: both;
}

/* ---- .element-item ---- */

.element-item {
    position: relative;
    float: left;
    width: 100px;
    height: 100px;
    margin: 5px;
    padding: 10px;
    background: #888;
    color: #262524;
}

.element-item > * {
    margin: 0;
    padding: 0;
}

.element-item .name {
    position: absolute;

    left: 10px;
    top: 60px;
    text-transform: none;
    letter-spacing: 0;
    font-size: 12px;
    font-weight: normal;
}

.element-item .symbol {
    position: absolute;
    left: 10px;
    top: 0px;
    font-size: 42px;
    font-weight: bold;
    color: white;
}

.element-item .number {
    position: absolute;
    right: 8px;
    top: 5px;
}

.element-item .weight {
    position: absolute;
    left: 10px;
    top: 76px;
    font-size: 12px;
}

.element-item.alkali          { background: #F00; background: hsl(   0, 100%, 50%); }
.element-item.alkaline-earth  { background: #F80; background: hsl(  36, 100%, 50%); }
.element-item.lanthanoid      { background: #FF0; background: hsl(  72, 100%, 50%); }
.element-item.actinoid        { background: #0F0; background: hsl( 108, 100%, 50%); }
.element-item.transition      { background: #0F8; background: hsl( 144, 100%, 50%); }
.element-item.post-transition { background: #0FF; background: hsl( 180, 100%, 50%); }
.element-item.metalloid       { background: #08F; background: hsl( 216, 100%, 50%); }
.element-item.diatomic        { background: #00F; background: hsl( 252, 100%, 50%); }
.element-item.halogen         { background: #F0F; background: hsl( 288, 100%, 50%); }
.element-item.noble-gas       { background: #F08; background: hsl( 324, 100%, 50%); }
