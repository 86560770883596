.header {
  @include row;
  background: transparentize(#000, 0.3);
  @include position(fixed, 0 0 null 0);
  z-index: 110;
  transition: background .7s cubic-bezier(.25, .8, .25, 1);
  font-family: $ghwebpro;
  text-transform: uppercase;

  @include media($mobile) {
    min-height: 98px;
  }

  // active class added to header on scroll -- main.js
  &.active {
    background: transparentize(#000, 0.1);
    @extend .card-2;

    @include media($desktop) {
      .header--inner {
        padding: 5px 10px 5px 10px;
      }
    };

    // relocating superfish hover to fit with the new active padding on header--inner
    .nav ul .sfHover {

      ul {
        margin-top: 47px;

        ul {
          margin-top: 0;
        }
      }
    }
  }

  &--inner {
    @include row();
    @include inner-container;
    padding: 20px 10px 15px 10px;
    transition: all .7s cubic-bezier(.25, .8, .25, 1);

    .logo {
      @include span-columns(2);

      @include media($tablet) {
        @include span-columns(3);
      };

        @include media(new-breakpoint(min-width 992px max-width 1024px 12)) {
            @include span-columns(2);
        }


        @include media($desktop) {
        @include span-columns(2);
      };
    }

    .block-helium-mobile-menu-helium-mobile-menu-menu-toggle {
      margin: 0;
      padding: 0;

      @include media(new-breakpoint(min-width 992px max-width 1024px 12)) {
        display: none;
      }

      @include media($desktop) {
        display: none;
      };
    }

    .nav {
      font-weight: bold;
      display: none;
      transition: all .3s cubic-bezier(.25, .8, .25, 1);
      @include font-size(20px);

        @include media(new-breakpoint(min-width 992px max-width 1024px 12)) {
            @include span-columns(6);
            @include shift(0.5);
        }

      @include media($desktop) {
        @include span-columns(8);
        @include shift(0.5);
      };

      ul {
        padding: 20px 0 0 0;
        margin: 0;

        li {

          &:not(:last-child) {
            margin-right: 35px;
          }

          a {
            color: $st_white;
            font-weight: normal;
            letter-spacing: 1px;

            &:hover {
              color: $st_red;
              text-decoration: none;
            }
          }

          &.sfHover {

            ul {
              @extend .card-1;
              border-top: 0;
              border-radius: 0;
              background: transparentize(#000, 0.3);
              width: auto !important;
              min-width: 150px;
              padding: 0;
              top: 0;
              margin-top: 56px;

              li {
                padding: 5px 10px 5px 10px;
                margin: 0;
                display: block;

                a {
                  padding: 10px 10px;
                  @include font-size(15px);
                  display: block;
                  white-space: nowrap;

                  &:after {
                    width: 40px;
                  }
                }

                ul {
                  margin-top: 0;
                }
              }
            }
          }
        }
      }
    }

    .right-menu {
      display: none;

        @include media(new-breakpoint(min-width 992px max-width 1024px 12)) {
            @include span-columns(3.5);
        }


        @include media($desktop) {
        @include span-columns(1.5);
      };

      ul {
        padding: 20px 0 0 0;
        margin: 0;
        float: right;

        li {
          display: inline-block;

          @include first(1) {

            a {
              @include ghblack;
              margin-right: 20px;
            }
          };
          &:not(:last-child) {
            margin-right: 15px;
          }

          a {
            font-family: $ghwebpro;
            color: $st_white;
            font-weight: normal;
            letter-spacing: 1px;
            @include font-size(20px);

            &:hover {
              color: $st_red;
              text-decoration: none;
            }

            &.right-menu {

              &--facebook {
                background: url(../images/social_icons/social-facebook.png) no-repeat;
                display: block;
                width: 25px;
                height: 25px;
                text-indent: -9999px;

                &:hover {
                  background: url(../images/social_icons/social-facebook--hover.png) no-repeat;
                }
              }

              &--instagram {
                background: url(../images/social_icons/social-instagram.png) no-repeat;
                display: block;
                width: 25px;
                height: 25px;
                text-indent: -9999px;

                &:hover {
                  background: url(../images/social_icons/social-instagram--hover.png) no-repeat;
                }
              }
            }
          }

          &.sfHover {

            ul {
              @extend .card-1;
              border-top: 0;
              border-radius: 0;
              background: transparentize(#000, 0.3);
              width: auto !important;
              min-width: 150px;
              padding: 0;
              top: 0;
              margin-top: 56px;

              li {
                padding: 5px 10px 5px 10px;
                margin: 0;
                display: block;

                a {
                  padding: 10px 10px;
                  @include font-size(15px);
                  display: block;
                  white-space: nowrap;

                  &:after {
                    width: 40px;
                  }
                }

                ul {
                  margin-top: 0;
                }
              }
            }
          }
        }
      }
    }

    .mobile-menu-toggle {
      @include span-columns(2);
      @include omega;
      padding-right: 10px;
      padding-top: 6px;

      .hmm-toggle {
        float: right;
      }

      @include media($tablet) {
        @include span-columns(4);
      };

        @include media(new-breakpoint(min-width 992px max-width 1024px 12)) {
            display: none;
        }

      @include media($desktop) {
        display: none;
      };
    }
  }

  &__sub {
      top: 98px;
      position: relative;
    transition: all .7s cubic-bezier(.25, .8, .25, 1);
  }
}

body.admin-menu {
  .header {
    top: 29px;
  }
}

.menu-bar {
  position: absolute;
  top: 98px;
  left: 0;
  width: 100%;
  z-index: 999;
}
