.footer {
    @include row();
    color: $st_white;
    margin-top: $section-fit;

    &__top {
        @include row();
        padding: 220px 0 50px 0;

        @include media($mobile) {
            text-align: center;
        };

        h1 {
            @include font-size(30px);
            text-transform: uppercase;
        }

        &--menu {
            @include span-columns(2);
            @include omega;

            .field-footer-menu {

                &:not(:last-child) {
                    margin-bottom: 7px;
                }

                a {
                    color: #fff;
                    @include font-size(16px);
                    line-height: 200%;

                    &:hover {
                        color: $st_red;
                    }
                }
            }

            @include media($desktop) {
                @include span-columns(3);
            };
        }

        &--find-us {
            @include span-columns(2);

            @include media($desktop) {
                @include span-columns(3);
            };

            p {
                margin-top: 0;
                line-height: 200%;

                a {
                    color: $st_red;
                }
            }
        }

        &--newsletter {
            @include span-columns(4);
            margin-top: 30px;

            @include media($tablet) {
                margin-top: 0;
                @include span-columns(8);
            };

            @include media($desktop) {
                @include span-columns(6);
            };
        }

        #mc_embed_signup {
            margin-top: 30px;

            $pad:           15px 25px;
            $radius:        50px;
            $min-height:    50px;

            @include media($desktop) {
                @include span-columns(6 of 6);
            };

            input.email {
                border: 0;
                padding: $pad;
                border-radius: $radius;
                font-weight: 300;
                height: $min-height;
                @include span-columns(3 of 4);
                @include shift(0.2);

                @include media($tablet) {
                    @include span-columns(6.5 of 8);
                    @include shift(0);
                };

                @include media($desktop) {
                    @include span-columns(4.5 of 6);
                };
            }

            input.submit {
                background: $st_red;
                border: 0;
                padding: $pad;
                color: #fff;
                border-radius: $radius;
                transition: all .3s;
                height: $min-height;
                @include span-columns(1.5 of 4);
                @include shift(-1);

                @include media($tablet) {
                    @include span-columns(1.5 of 8);
                    @include shift(-1);
                };

                @include media($desktop) {
                    margin-left: -70px;
                    @include span-columns(1.5 of 6);
                };

                &:hover {
                    transform: translateX(-20px);
                    background-color: #fb4b4b;
                }
            }
        }
    }

    &__bottom {
        @include row();
        padding: 25px 0;
        background: #1a1a1a;

        &--sponsors {
            @include span-columns(4);
            text-align: center;

            @include media($tablet) {
                @include span-columns(8);
            };

            @include media($desktop) {
                @include span-columns(8);
            };

            .sponsor {
                @include span-columns(4 of 4);
                margin-bottom: 30px;

                img {
                    max-width: 330px;
                }

                @include media($mobile) {
                    img {
                        max-width: 100%;
                    }
                };
                @include media($desktop) {
                    @include span-columns(2 of 8);

                    img {
                        max-width: 100%;
                    }
                };
            }
        }

        &--social {
            @include span-columns(4);

            @include media($mobile) {
                margin-top: 20px;
            };
            @include media($desktop) {
                @include span-columns(3);
                @include shift(1);
            };

            .social {
                @include span-columns(2 of 4);
                text-indent: -9999px;
                display: block;
                height: 36px;
                margin-bottom: 30px;

                @include media($tablet) {
                    margin-bottom: 0;
                };

                @include media($desktop) {
                    @include span-columns(2.5 of 5);
                };

                &.facebook {
                    background: url('../images/footer/facebook.png') no-repeat center center;
                    background-size: contain;
                }

                &.instagram {
                    background: url('../images/footer/instagram.png') no-repeat center center;
                    background-size: contain;
                    margin-top: 7px;
                }
            }
        }
    }
}
