.main {
  @include container;

  &__content {
    @include inner-container;
  }
}

body.not-front {
  .main {
    //background-image: url('/sites/default/files/styles/fullscreen/public/mediearkiv/fullscreen/body-bg.jpg?itok=qvC7HWwV');
    background-attachment: fixed;
    background-size: cover;
  }

  &.custom-page {

    .main {
      padding-top: 125px;
    }
  }

  &.page-event {
    .main {
      padding-bottom: 0;
      padding-top: 96px;
    }
  }

  &.page-galleri,
  &.node-type-gallery {
    .main {
      padding-top: 155px;

      &__content {
        padding: 15px;
      }
    }
  }

  .main--single {
    padding-top: 155px;
    padding-bottom: 50px;
  }
}


.footer {
  @include container;
}
