#gallery {
    margin-top: $section-fit;
    padding-bottom: 300px;

    h1 {
        color: #fff;
    }

    .gallery-row {
        @include row();
        margin-bottom: 50px;
    }

    .btn--gallery {
        @include btn-load;
    }

}
.view-gallery {
  .gallery-item {
      position: relative;
      margin-bottom: 30px;
      border: 5px solid transparentize(#fff, 0.20);

      @include media($earlybird) {
        @include span-columns(4);
        @include omega(2n);
      };

      @include media($tablet) {
        @include omega-reset(2n);
        @include span-columns(2.66666666);
        @include omega(3n);
      };

      @include media($desktop) {
        @include omega-reset(2n);
         @include span-columns(4);
          @include omega(3n);
      };

      img {
        margin: 0;
      }

      &__date {
        top: 0;
        right: 20px;
        background: #fa3232;
        padding: 3px 3px 6px 3px;
        text-transform: uppercase;
        position: absolute;
        color: #fff;

        p {
            text-align: center;
            display: block;
            margin: 0;
            font-weight: bold;
            @include font-size(30px);
            margin-bottom: 0;
            line-height: 0.9;
            @include ghblack;
            padding: 0 2px;

            span {
                display: block;
                margin: 0;
                font-weight: 300;
                @include font-size(20px);
                font-family: $ghwebpro;
            }
        }
    }

    &__meta {
      position: absolute;
      text-align: center;
      transform: translate(-50%, -50%);
      bottom: 30%;
      left: 50%;
      width: 100%;

        h1, a {
            @include font-size(30px);
            @include ghblack;
            margin: 0;
            color: $st_white;
            text-shadow: -1px -1px 0 rgba(25,25,25,.6),
            -2px -2px 1px rgba(25,25,25,.3);
            transform: rotate(-5deg) skew(-5deg, 0) translateY(30px);
            text-transform: uppercase;
            transition: transform 0.35s;
            border: 0;

            &:hover {
              border: 0;
            }
        }
      }
    }
}


.node-type-gallery {

  .main {
    padding-top: 175px;
    padding-bottom: 100px;


    .field-galley-images {
      margin-bottom: 30px;
      overflow: hidden;

      .field-item {
        margin-bottom: 30px;
          @include media($earlybird) {
              @include span-columns(4);
              @include omega(2n);
          };

        @include media($tablet) {
            @include span-columns(2.6666);
            @include omega-reset(2n);
            @include omega(3n);
        };

        @include media($desktop) {
          @include span-columns(4);
            @include omega-reset(2n);
          @include omega(3n);
        };
      }
    }
  }
}
