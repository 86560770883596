.event-popout {
    padding: 50px 0;
    color: #333;
    position: relative;
    background: white;

    .actions {
        // @include inner-container;
        //position: relative;

        .slider-controls--desktop {
            @include font-size(28px);
            color: #fff;
            z-index: 100;
            position: absolute;
            top: 200px;
            background: transparentize(#000, 0.3);
            display: none;
            transition: all .15s ease;

            @include media(1300px) {
                display: block;
            };

            &:hover {
                background: transparentize(#000, 0.15);
            }

            &.fa-chevron-left {
                left: 5%;
                padding: 10px 10px 10px 8px;
            }

            &.fa-chevron-right {
                right: 5%;
                padding: 10px 8px 10px 10px;
            }
        }
    }



    .inner {
        @include inner-container;
        padding-left: 50px;
        padding-right: 50px;
    }

    &__meta {
        @include row();

        &.artist {
            display: none;

            &:first-child  {
                display: block;
            }
        }

        p {
            margin: 5px 0;
        }

        &--left {
            @include media($tablet) {
                @include span-columns(8);
                @include row();
            };
            @include media($desktop) {
                @include span-columns(8);
            };

            &__cta {
              display: block;

              @include media($tablet) {
                display: none;
              };

              .btn.btn--ticket {
                width: 100%;
                margin-top: -20px;
              }
            }

            &__some {
                position: relative;
                .video-trigger {
                    @include absolute-center;

                    i {
                        @include font-size(75px);
                        color: rgba(255,255,255,0.8);
                        background: transparentize(#000, 0.8);
                        padding: 30px;
                        border-radius: 50%;
                        cursor: pointer;
                    }
                }

                .slider-controls {
                    @include font-size(25px);
                    padding: 5px;
                    color: #fff;
                    z-index: 100;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    background: transparentize($st_red, 0.3);
                    transition: all .15s ease;

                    @include media($tablet) {
                        @include font-size(18px);
                        padding: 8px;
                    };

                    @include media(1300px) {
                        display: none;
                    };

                    &:hover {
                        background: transparentize(#000, 0.15);
                    }

                    &.fa-chevron-left {
                        left: 3%;
                    }

                    &.fa-chevron-right {
                        right: 3%;
                    }
                }
            }

            &__artist-title {
                margin: 10px 0 30px 0;

                @include media($tablet) {
                    @include span-columns(5 of 8);
                };

                @include media($desktop) {
                    @include span-columns(5 of 8);
                };

                a {
                    color: $st_lightgray;

                    &:not(:first-child) {
                        padding-left: 15px;
                    }

                    &.active {
                        color: $st_black;
                        cursor: default;
                    }

                    &:not(:last-child) {
                        padding-right: 15px;
                        border-right: 1px solid $st_black;
                    }

                    h3 {
                        display: inline-block;
                        margin: 0;
                        text-transform: uppercase;
                        @include ghblack;
                        @include font-size(22px);
                    }
                }
            }

            &__social {
                margin: 10px 0;

                @include media($tablet) {
                    @include span-columns(3 of 8);
                    @include omega;
                    text-align: right;
                };

                @include media($desktop) {
                    @include span-columns(3 of 8);
                    padding-right: 10px;
                    @include omega;
                };

                a {
                    color: $st_black;
                    @include font-size(20px);
                    transition: color .2s;
                    line-height: 1;

                    &:not(:last-child) {
                        margin-right: 12px;
                    }

                    &:hover {
                        color: $st_red;
                    }
                }
            }

            &__sharebox {
                display: none;
                clear: both;

                .sharebox__icons {
                    margin-bottom: 10px;

                    .fa {
                        font-size: 30px;

                        &:not(:last-child) {
                            margin-right: 3px;
                        }
                    }
                }

                .sharebox__share {
                    margin-bottom: 30px;

                    input {
                        padding: 5px 10px;
                        color: #888;
                        font-weight: normal;
                        height: auto;
                        display: block;
                        width: 300px;
                    }
                }
            }

            &__description {
                clear: both;
                overflow: hidden;
                font-weight: normal;

                ul, li {
                    list-style: disc outside none;
                }

            }
        }

        &--right {

            @include media($tablet) {
                @include span-columns(8);
            };

            @include media($desktop) {
                @include span-columns(4);
            };

            h1 {
                @include font-size(30px);
                margin: 0;
                font-weight: normal;
                text-transform: uppercase;
                line-height: 1;
            }

            h2 {
                @include font-size(22px);
                margin: 0;
                font-family: $ghwebpro;
            }

            p {
                font-family: $ghwebpro;
                @include font-size(20px);
                margin: 0;
            }

            .wrap {
                margin: 20px 0;

                p {
                    line-height: 1;
                }
            }

            .date {
                color: $st_red;
                @include ghblack;
                @include font-size(22px);
            }

            .prices {

                p {
                    margin: 0;
                    font-family: $ghwebpro;
                    @include font-size(20px);
                    line-height: 1;

                    @include first(1) {
                        margin-top: 30px;
                    };
                }

                span {
                    float: right;
                }
            }

            .btn--ticket {
                margin: 60px 0 0 0;
                width: 100%;
                border-radius: 0;

                p {
                    @include font-size(28px);
                    @include ghblack;
                }
            }

            &__related-concerts {
                margin-top: 30px;
                @include row();

                p {
                    display: block;
                    font-weight: bold;
                    line-height: normal;
                    font-style: italic;
                    @include font-size(15px);
                    margin-bottom: 8px;

                    @include media($desktop) {
                        @include span-columns(4 of 4);
                        display: inline-block;;
                    };

                    i {
                        float: right;
                        @include font-size(16px);
                        margin-right: 10px;
                    }
                }

                .related-concert {
                    cursor: pointer;
                    @include span-columns(2 of 4);

                    @include media($desktop) {
                        @include span-columns(2 of 4);
                    };

                    p {
                        margin-top: 5px;
                        @include font-size(14px);
                        text-transform: uppercase;
                        font-style: normal;

                        span {
                            color: $st_red;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }

    &--meta {
        text-transform: uppercase;

        @include media($desktop) {
            @include span-columns(4);
        };

        h1 {
            @include font-size(25px);
            font-weight: bold;
            margin-bottom: 0;
        }

        h2 {
            margin: 0;
            @include font-size(16px);
        }

        &__price {
            @include row;
            margin: 75px 0;

            p {
                margin: 0;

                span {
                    float: right;
                }
            }
        }
    }
}
