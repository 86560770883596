@import "../../bower_components/neat/app/assets/stylesheets/_neat-helpers.scss";


// grid settings

$column:                90px;
$gutter:                30px;
$grid-columns:          4;
$grid-max-width:        1050px;


// Visual grid settings

$visual-grid:           false;
$visual-grid-color:     red;
$visual-grid-index:     front;
$visual-grid-opacity:   0.1;


// Mobile first breakpoint variables
$sm:                    500px;
$md:                    768px;  // 768px  -- Medium screen - tablet
$lg:                    1025px;  // 992px  -- Large screen / desktop
$xl:                    1200px; // 1200px -- Extra large screen / wide desktop


$earlybird:             new-breakpoint(min-width 500px 8);

// Extra small devices (portrait phones, less than 34em)
$mobile:                new-breakpoint(max-width $sm 4);

// Medium devices (tablets, 48em and up)
$tablet:                new-breakpoint(min-width $md 8);

$tablet-landscape:      new-breakpoint(min-width $lg max-width 1024px 8);

// Large devices (desktops, 62em and up)
$desktop:               new-breakpoint(min-width $lg 12);

// Extra large devices (large desktops, 75em and up)
$desktop-xl:            new-breakpoint(min-width $xl 12);


$outer-grid-max-width:     1920px;
$xl-inner-grid-max-width:  1050px;
$lg-inner-grid-max-width:  1050px;


@mixin inner-container {
    padding-left: 15px;
    padding-right: 15px;

    @include media($desktop) {
        @include outer-container($lg-inner-grid-max-width);
    };

    @include media($desktop-xl) {
        @include outer-container($xl-inner-grid-max-width);
    };
}

// Container mixin
@mixin container {
    @include outer-container($outer-grid-max-width);
    overflow: hidden;
}
