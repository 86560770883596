/*
#event-feed-section {
    background-image: url('/images/section-about-stars.jpg');
    background-size: cover;
    background-attachment: fixed;
}
*/

.no-banner {
    #event-section {
        padding-top: 130px;
    }
}

#event-section,
#calendar-list {
    @include row();
    padding-bottom: 300px;

    .events-wrapper {
        @include row();
        @include inner-container;
    }

    .events {
        flex-wrap: wrap;

        .event {
            position: relative;
            color: #fff;
            cursor: pointer;
            overflow: hidden;
            width: 100%;
            display: flex;
            margin: 0 0 20px;
            float: left;
            aspect-ratio: 1 / 1;

            &:hover {
                .event__figcaption {
                    img {
                        opacity: 0.6;
                    }

                    h2 {
                        transform: translate3d(0,0,0);
                    }

                    p {

                        i {
                            opacity: 1;
                            transform: translate3d(0,20px,0);
                        }

                        a {
                            opacity: 1;
                        }
                    }

                    &::after {
                        transform: translate(-1%, 60%) rotate(-5deg) skew(-5deg, 0);
                    }
                }
            }

            @include media($earlybird) {
                width: 46%;
                margin: 2%;
            };

            @include media($desktop) {
                width: 30%;
                margin: 1.5%;
            };

            &__image {
                z-index: 1;

                &.active {
                    @include white-overlay;
                }

                img {
                    transition: opacity 0.45s;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &__date,
            &__state,
            &__meta {
                position: absolute;
                z-index: 1;
            }

            &__date {
                top: 0;
                right: 20px;
                background: #fa3232;
                padding: 3px 3px 6px 3px;
                text-transform: uppercase;

                p {
                    text-align: center;
                    display: block;
                    margin: 0;
                    font-weight: bold;
                    @include font-size(30px);
                    margin-bottom: 0;
                    line-height: 0.9;
                    @include ghblack;
                    padding: 0 2px;

                    span {
                        display: block;
                        margin: 0;
                        font-weight: 300;
                        @include font-size(20px);
                        font-family: $ghwebpro;
                    }
                }
            }

            &__state {
                position: absolute;
                bottom: 98px;
                left: -5px;
                //width: calc(100% - 59px);
                //padding: 17px 10px;
                //background: transparentize($st_black, 0.15);
                text-transform: uppercase;
                //@include font-size(25px);
                line-height: 1;
                letter-spacing: 0.6px;
                font-family: $ghwebpro;
                z-index: 20;

                ul, li {
                    margin: 0;
                    padding: 0;
                }

                ul {
                    li {
                        display: block;
                        margin: 2px 0;
                        @include transform(rotate(-5deg));


                        span {
                            display: inline-block;
                            background: $st_red;
                            padding: 4px;
                            padding-left: 10px;

                        }
                    }
                }

            }

            &__meta {
                text-align: center;
                transform: translate(-50%, -50%);
                bottom: 21%;
                left: 50%;
                width: 100%;
                z-index: 20;


                h1 {
                    @include font-size(30px);
                    @include ghblack;
                    margin: 0;
                    text-shadow: -1px -1px 0 rgba(25,25,25,.6),
                    -2px -2px 1px rgba(25,25,25,.3);
                    transform: rotate(-5deg) skew(-5deg, 0) translateY(60px);
                    text-transform: uppercase;
                    transition: transform 0.35s;
                    height: 60px; // Force the height so all titles start at the same vertical
                    line-height: 1;
                }
            }

            &__figcaption {
                @include ab-fill;
                z-index: 10;

                &::after {
                    content: "";
                    background: transparentize($black, 0.1);
                    @include ab-fill;
                    transform: translateY(100%);
                    width: 102%;
                    transition: transform .3s;
                }

                p {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    padding: 0;
                    z-index: 20;

                    a {
                        @include font-size(18px);
                        @include ghblack;
                        color: $st_red;
                        transform: rotate(-5deg);
                        display: inline-block;
                        text-transform: uppercase;
                        letter-spacing: 0;
                        opacity: 0;
                        transition: opacity 0.20s, transform 0.35s;
                        padding-right: 10px;

                        i {
                            opacity: 0;
                            transition: opacity 0.20s, transform 0.35s;
                            transform: translate3d(0,50px,0);
                            color: $st_white;

                            &:hover {
                                color: $st_red;
                            }
                        }

                    }
                }

                a {
                    margin: 0 10px;
                    font-size: 170%;
                }
            }
        }

        .events-more {
            display: none;
        }
    }

    .event-load-more {

    }

    .btn--event--load {
        @include btn-load;
    }


    .events-popout-wrapper {
        @extend .card-1;
        @include row();
        @include omega();
        clear: both;
        position: relative;
        overflow: hidden;
        margin: 25px 0;

        @include media($desktop) {
            margin-left: calc(-100vw / 2 + 1030px / 2);
            margin-right: calc(-100vw / 2 + 1030px / 2);
        };


        $duration: .2s;
        $effect: ease-in-out;
        .event-popout-wrapper {
            position: absolute;
            // left: -100vw;
            width: 100%;
            opacity: 1;
            @include transition(left $duration $effect, right $duration $effect, opacity $duration $effect);

            &.active {
                position: relative;
                left: 0;
                opacity:1;
                z-index: 20;
            }


            &.right {
                top: 0;
                z-index: 10;
                left: 100vw;
                &.active {
                    left: 0;
                }
            }

            &.left {
                left: -100vw;
                &.active {
                    left: 0;
                }
            }

            &:first-child {
                .event-popout .slider-link.left { display: none; }
            }

            &:last-child {
                .event-popout .slider-link.right { display: none; }
            }




            @include media($desktop) {
                //max-width: 100vw;
                //position: relative;
                //left: 50vw;
                //@include transform(translateX(-15vw));
            };



        }


    }
}

.event__page {
    @include media($desktop) {
        margin-left: calc(-100vw / 2 + 1030px / 2);
        margin-right: calc(-100vw / 2 + 1030px / 2);
    };

    .actions {
        display: none;
    }
}
