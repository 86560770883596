.paragraphs-item-row {
  margin-bottom: 40px;
}

.paragraphs-items-field-columns {

  @include media($desktop) {
    display: flex;
    flex-flow: row wrap;
    gap: 40px;

  };

  & > .field-columns {
    @include media($desktop) {
      flex: 1 1 0;
      flex-flow: row wrap;
    };
  }
}

.field-text-long {
  > p:first-child {
    margin-top: 0;
  }
}

.field-content-image {
  margin-bottom: 1rem;

  img {
    margin: 0 auto;
    display: block;

    @include media($desktop) {
      margin: auto;
      display: inline;
    };
  }
}

.paragraphs-items-field-box-element {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 40px;

  &.box-layout--circle {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
    "a b d"
    "a c d";

    .field-box-element {
      &:nth-child(1) {
        grid-area: a;
        align-self: center;
      }
      &:nth-child(2) {
        grid-area: b;
      }
      &:nth-child(3) {
        grid-area: c;
      }
      &:nth-child(4) {
        grid-area: d;
        align-self: center;
      }

  }

}
.paragraphs-item-box-element {

    .field-box-header,
    .field-box-content {
      position: relative;
      z-index: 5;
    }

    .field-box-header {
      font-weight: bold;
      @include font-size(25px);
      margin-bottom: 2rem;
    }

    .field-box-content {}

    .field-box-icon {
      img {
        position: absolute;
        top: -20px;
        opacity: .5;
        left: 50%;
        transform: translateX(-50%);
        z-index: 0;
      }
    }
  }
}



.field-box-element {
  overflow: hidden;
  border: 3px dashed white;
  text-align: center;
  position: relative;


   .paragraphs-items-field-box-element.background--red & {
    background-color: transparentize($st_red, 0.12);
  }
   .paragraphs-items-field-box-element.background--white & {
    background-color: transparentize($st_white, 0.12);
  }
   .paragraphs-items-field-box-element.background--gray-light & {
    background-color: transparentize($st_lightgray, 0.12);
  }
   .paragraphs-items-field-box-element.background--gray-dark & {
    background-color: transparentize($st_black, 0.12);
  }
   .paragraphs-items-field-box-element.background--black & {
    background-color: transparentize($black, 0.12);
  }


  .box-layout--square & {
    padding: 2rem 1rem;
  }

  .box-layout--circle & {
    width: 300px;
    height: 300px;
    border-radius: 50%;

    &:before {
      content: "";
      float: left;
      height: 100%;
      width: 50%;
      shape-outside: polygon(
          0 0,
          98% 0,
          50% 6%,
          23.4% 17.3%,
          6% 32.6%,
          0 50%,
          6% 65.6%,
          23.4% 82.7%,
          50% 94%,
          98% 100%,
          0 100%
      );
      shape-margin: 7%;
    }

    .content {
      height: 300px;
      width: 300px;
      &:before {
        content: "";
        float: right;
        height: 100%;
        width: 50%;
        shape-outside: polygon(
            2% 0%,
            100% 0%,
            100% 100%,
            2% 100%,
            50% 94%,
            76.6% 82.7%,
            94% 65.6%,
            100% 50%,
            94% 32.6%,
            76.6% 17.3%,
            50% 6%
        );
        shape-margin: 7%
      }

    }

  }

}


// Circle clip
.paragraphs-item-box-element {



}
