.page-user {
  background: #fff;
  padding: 20px;

  .main {
    height: 100%;

    &__content {
      @include media($desktop) {

      };

      .form-required {
        margin: 30px 0;

        @include first(1) {
          margin-top: 50px;
        };

        label {
          font-weight: 400;
          display: none;
        }

        input {
          display: block;
          max-width: 600px;
          border: none;
          padding: 10px 10px !important;
          background-color: #f0f0f0;
          margin: 5px 0 10px 0;
          width: 100%;
          padding: 10px;
          border: none;
          transition: all 0.3s;

          &:focus {
          }

          &::placeholder {
            @include font-size(14px);
          }
        }
      }

      input[type=submit] {
        @extend .btn--primary;
      }

      .messages {
        margin-top: 50px;

        a {
          color: $st_red;
        }
      }
    }
  }
}
