.calendar {
    margin-bottom: 100px;

    @include media($tablet-landscape) {
        margin-bottom: 200px;
    };
    .events {
        @include row();

        .event-calendar-item {

            @include media($tablet) {
                padding: 15px 20px;
            };
            
            .flex-wrapper {
                display: flex;
                margin-bottom: 1rem;
                padding: 10px;
                background: transparentize(white, 0.2);
                cursor: pointer;
                
                > div {
                    align-self: center;
                }
            }
            
            .event-popout-wrapper {
                background: transparentize(white, 0.2);
                padding: 10px;
                position: relative !important;
            }

            &__date {
                @include font-size(46.4px);
                font-weight: bold;
                @include ghblack;
                width: 20%;

                @include media($mobile) {
                    @include font-size(30px);
                };
                @include media($tablet) {
                    width: 10%;
                }
            }

            &__meta {
                text-transform: uppercase;
                overflow: hidden;
                flex-grow: 1;
                margin-left: 5%;
                margin-right: 5%;

                @include media($tablet) {
                    flex-grow: 1;
                    margin-left: 5%;
                    margin-right: 5%;
                }



                p:last-child {
                    display: none;
                    @include media($desktop) {
                        display: inline;
                    }
                }

                h1 {
                    margin: 0;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    @include font-size(27.2px);
                    line-height: 1;
                }

                p {
                    margin: 0;
                    @include font-size(13.5px);
                    display: inline-block;

                    &.bold {
                        font-weight: bold;

                        span {
                            font-weight: normal;
                        }
                    }
                }
            }

            .actions {

            }

            &__ticket {
                margin-left: auto;
                width: 10%;

                a span {
                    display: none;
                }

                @include media($tablet) {
                    a span {
                        display: inline;
                    }
                }
            }
        }
    }
}
