// fonts
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700,600);
// @import url("//hello.myfonts.net/count/32989f");

@font-face {
  font-family: 'FFGoodHeadlineWebProCond';
  src: url('../fonts/32989F_0_0.eot');
  src: url('../fonts/32989F_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/32989F_0_0.woff2') format('woff2'),url('../fonts/32989F_0_0.woff') format('woff'),url('../fonts/32989F_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'FFGoodHeadlineWebProCondBlack';
  src: url('../fonts/32989F_1_0.eot');
  src: url('../fonts/32989F_1_0.eot?#iefix') format('embedded-opentype'),url('../fonts/32989F_1_0.woff2') format('woff2'),url('../fonts/32989F_1_0.woff') format('woff'),url('../fonts/32989F_1_0.ttf') format('truetype');
}

$opensans: 'Open Sans', sans-serif;
$ghwebpro: 'FFGoodHeadlineWebProCond';
$ghwebproblack: 'FFGoodHeadlineWebProCondBlack';

// colors
$black: #000000;
$st_red: #fa3232;
$st_white: #ffffff;
$st_black: #2b2b2b;
$st_lightgray: #8e8e8e;


$section-fit: -175px;


$fa3: #fa3232;
$fff: #ffffff;
$ae8: #8e8e8e;
$bb2: #2b2b2b;
$c00: #000000;
$dbc: #AC725E;
$AC7: #D06B64;
$D06: #F83A22;
$F83: #FA573C;
$FA5: #FF7537;
$FF7: #FFAD46;
$FFA: #42D692;
$a2D: #16A765;
$a6A: #7BD148;
$aBD: #B3DC6C;
$B3D: #FBE983;
$FBE: #92E1C0;
$a2E: #9FE1E7;
$aFE: #9FC6E7;
$aFC: #4986E7;
$a98: #9A9CFF;
$aA9: #B99AFF;
$B99: #C2C2C2;
$C2C: #CABDBF;
$CAB: #CCA6AC;
$CCA: #F691B2;
$F69: #CD74E6;
$CD7: #A47AE2;
