// drupals user tabs
ul.tabs {

  li {
    padding: 0;

    &:not(:last-child) {
      margin-right: 20px;
    }

    a {
      color: #444;
      font-weight: 400;
      @include font-size(14px);
    }
  }
}

// removes box shadow on drupal admin menu
#admin-menu {
	box-shadow: none;
}

// Contextual links mothership fix
.contextual-links-region:hover a.contextual-links-trigger {
  display: block;
 }
